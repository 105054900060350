import { map } from 'rxjs/operators';
import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { APP_CONFIG, IAppEnvConfig } from '@qnp/qnp-common';

@Injectable()
export class PageService {
	constructor(private http: HttpClient, @Inject(APP_CONFIG) private config: IAppEnvConfig) {}

	publicContentApiUrl = `${this.config.publicGateway}${this.config.publicContentApi}`;
	//publicContentApiUrl = `http://localhost:3000/public`;

	getPageByPermalink(permalink: string) {
		const url = `${this.publicContentApiUrl}/${permalink}`;
		const headers = {
			observe: 'response' as 'body',
			responseType: 'text' as 'json',
			headers: { cachepurge: 'true', 'x-hcms-origin': `https://cms.gov` },
		};
		return this.http.get(url, headers);
	}

	getPreviewPage(id) {
		const headers = new HttpHeaders().set(
			'Authorization',
			`Bearer ${window.localStorage.getItem('okta-token-storage')}`
		);
		headers.set('x-hcms-origin', `https://cms.gov`);
		return this.http.get(`${this.config.apiGateway}/contentManager/preview/page/${id}`, {
			headers,
			observe: 'response' as 'body',
			responseType: 'text' as 'json',
		});
	}

	getFileById(id: string) {
		const url = `${this.publicContentApiUrl}/files/${id}`;
		return this.http.get(url);
	}

	getS3File(s3Url: string) {
		return this.http.get(s3Url, {
			observe: 'response' as 'body',
			responseType: 'blob',
		});
	}

	searchEntries(type, term, page) {
		// to be modified as per solar syntax
		if (type === 'page') {
			const url = `${this.publicContentApiUrl}/searchPages`;
			return this.http.post(url, { searchTerms: term, page }).pipe(map(res => ({ ...res, type })));
		} else if (type === 'file') {
			const url = `${this.publicContentApiUrl}/searchFiles`;
			return this.http.post(url, { searchTerms: term, page }).pipe(map(res => ({ ...res, type })));
		}
	}

	getNewsArticles(page) {
		const url = `${this.publicContentApiUrl}/newsArticlesList?page=${page}`;
		return this.http.get<any>(url);
	}

	getLists(page, type?: string, search?: string) {
		let url = `${this.publicContentApiUrl}/lists?page=${page}`;
		if (type === 'private') {
			url += '&private=true';
		}
		if (search) {
			url += `&search=${encodeURIComponent(search)}`;
		}
		return this.http.get<any>(url);
	}

	getTags() {
		return this.http.get<any>(`${this.publicContentApiUrl}/tags`);
	}

	sendLists(payload) {
		return this.http
			.post<any>(`${this.publicContentApiUrl}/join/list`, payload)
			.pipe(map(res => res));
	}

	gets3Link(id, alt = '', imgClass = '') {
		const url = `${this.publicContentApiUrl}/render/file`;
		const headers = new HttpHeaders().set('cachepurge', 'true');
		return this.http.post<any>(url, { id: id, imgStyle: imgClass, altText: alt }, { headers });
	}

	getQIOStates() {
		const url = `${this.publicContentApiUrl}/qio/states`;
		const headers = new HttpHeaders().set('cachepurge', 'true');
		return this.http.get<any>(url, { headers });
	}

	getQIODetails(stateCode: string) {
		const url = `${this.publicContentApiUrl}/qio/${stateCode}`;
		return this.http.get<any>(url);
	}
}

import { Component, OnInit } from '@angular/core';
import { PageService } from '../../services/page.service';
import { Router } from '@angular/router';
import { ToastrService } from '@qnp/qnp-common';

@Component({
	selector: 'app-qio-program',
	templateUrl: './qio-program.component.html',
})
export class QIOProgramComponent implements OnInit {
	qioStates: any[];
	selectedStateCode: string = '';
	stateDetails: any = null;

	constructor(
		private publicContentService: PageService,
		private router: Router,
		private toastr: ToastrService
	) {}

	ngOnInit(): void {
		this.getQIOStates();
	}

	getQIOStates() {
		this.publicContentService.getQIOStates().subscribe(
			(results: any) => {
				this.qioStates = results;
			},
			() => this.toastr.error('Unable to load QIO States. Please try refreshing the page!')
		);
	}

	onStateChange() {
		this.publicContentService.getQIODetails(this.selectedStateCode).subscribe(
			(data: any) => {
				this.stateDetails = data;
			},
			() => this.toastr.error('Unable to load QIO Contact Details. Please try refreshing the page!')
		);
	}
}
